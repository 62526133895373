import { memo, useRef } from "react";
import { Row } from "reactstrap";
import useVisible from "@tteenbe/hookUseVisible";
import { Colxx } from "components/common/CustomBootstrap";
import { ImageAsyn } from "components/ImageAsyc";
import {
    // FadeInLeftSpring,
    FadeInRightSpring,
} from "components/animations/fadeIntTopSpring";
import img2 from "assets/image/contenido/img2.jpg";
import img from "assets/image/contenido/img.png";
import { url } from "localUrl";
// import samy from "assets/image/contenido/samyy.mkv";
import { NavLink } from "components/NavLink";

const AboutImageSection = () => {
    const elemRefButton1 = useRef();
    const isVisibleButton1 = useVisible(elemRefButton1);
    const elemRefButton2 = useRef();
    const isVisibleButton2 = useVisible(elemRefButton2);

    return (
        <div className="mad_full_width_grid">
            <Row className="pattern_section">
                <Colxx md="6" id="mad_item_second">
                    <div className="mad_pattern bg_pattern_dark clearfix">
                        <article className="f_right" ref={elemRefButton1}>
                            <h2>Servicios</h2>
                            <p>
                                En Samy Spa contamos para tí, con servicios en
                                las siguientes áreas. <br />
                                <span className="p-4">- Facial.</span>
                                <br />
                                <span className="p-4">- Corporal.</span>
                                <br />
                                <span className="p-4">- Relax.</span>
                                <br />
                                <span className="p-4">- Peluquería.</span>
                                <br />
                            </p>
                            {isVisibleButton1 && (
                                <NavLink
                                    to="../../servicios"
                                    className="mad_button style2"
                                >
                                    Ver Mas
                                </NavLink>
                            )}
                        </article>
                    </div>
                </Colxx>
                <Colxx md="6" id="mad_item_first">
                    {/* <ImageAsyn src={img1} className="w-100 " alt="" /> */}
                    <video width="100%" height="100%" poster={img} controls>
                        <source
                            src={`${url}/files/samyy.mkv`}
                            type="video/mkv"
                        />
                        <img src={img} alt="Video no soportado" />
                        Su navegador no soporta contenido multimedia.
                    </video>
                </Colxx>
            </Row>
            <div className="row pattern_section">
                <div className="col-md-6">
                    <div className="mad_pattern bg_pattern_red">
                        <article ref={elemRefButton2}>
                            <h2>Samy Spa</h2>
                            <p>
                                Posicionarnos como el spa urbano, más importante
                                de nuestra ciudad de El Alto ofreciendo calidad
                                y satisfaccion a todos nuestros clientes.
                            </p>
                            {isVisibleButton2 && (
                                <FadeInRightSpring>
                                    <NavLink
                                        to="../../samyspa"
                                        className="mad_button style2"
                                    >
                                        Acerca de Samy
                                    </NavLink>
                                </FadeInRightSpring>
                            )}
                        </article>
                    </div>
                </div>
                <div className="col-md-6">
                    <figure>
                        <ImageAsyn src={img2} className="w-100 h-100" alt="" />
                    </figure>
                </div>
            </div>
        </div>
    );
};

export default memo(AboutImageSection);
